import { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { API } from "aws-amplify";
import { useQuery } from "@tanstack/react-query";

import { CustomError } from "utils/error";
import { useDebounce } from "hooks/debounce";
import { getContact } from "graphql/queries";
import { Contact } from "API";
import { projectsByGroupIdForManager } from "graphql/queries";
import { Project as ProjectType } from "API";
import { useAlerts } from "contexts/alerts";

interface ProjectProps {
  defaultProjects?: ProjectType[];
  groupId: String | undefined;
}
export default function Project({ groupId, defaultProjects }: ProjectProps) {
  const { control, setValue, watch } = useFormContext();
  const [inputText, setInputText] = useState<string>("");
  const debouncedInputText = useDebounce(inputText, 500);
  const { addAlert } = useAlerts();

  const [selectedProject, setSelectedProject] = useState<{
    name: string | undefined;
    id: string | undefined;
  } | null>(null);
  const pastProjectId = watch("pastProjectId");

  const { data: projects = defaultProjects, isLoading } = useQuery({
    queryKey: ["projectsByGroupIdForManager", groupId, debouncedInputText],
    queryFn: async () => {
      let nextToken: string | null = null;
      let allItems: ProjectType[] = [];
      try {
        while (true) {
          const res: any = await API.graphql({
            query: projectsByGroupIdForManager,
            variables: {
              groupId,
              filter: {
                archived: { ne: true },
                name: { contains: debouncedInputText },
              },
              sortDirection: "DESC",
              nextToken,
            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
          const items = res.data.projectsByGroupIdForManager.items;
          allItems = allItems.concat(items);
          nextToken = res.data.projectsByGroupIdForManager.nextToken;
          if (!nextToken) break;
        }
        return allItems;
      } catch (err) {
        const customError = new CustomError(err, "get");
        addAlert({ message: customError.message, severity: "error" });
      }
    },
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 6,
    enabled: !!debouncedInputText,
  });

  const options = projects?.map((acc, idx) => ({
    name: acc.name,
    reference: acc.reference,
    id: acc.id,
    key: acc.id + idx,
  }));

  useEffect(() => {
    if (pastProjectId) {
      const project = projects?.find((p) => p.id === pastProjectId);
      if (project) {
        if (project.account?.active ?? true) {
          setValue("accountId", project.accountId);
          setValue("accountName", project.accountName);
        }
        // useEffectの型エラー回避のためawaitでなくthen使用
        getContactById(project.contactId).then((contact) => {
          if (!contact) {
            setValue("contactId", null);
            setValue("contactName", null);
          } else if (contact.active ?? true) {
            setValue("contactId", project.contactId);
            setValue("contactName", project.contactName);
          }
        });
        setValue("projectId", project.id);
        setValue("type", project.type);
        setSelectedProject({ name: project.name, id: project.id });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pastProjectId]);

  const getContactById = async (id: string): Promise<Contact | null> => {
    try {
      const contact = (
        await API.graphql({
          query: getContact,
          variables: { id },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        })
      ).data.getContact;
      return contact;
    } catch (err) {
      return null;
    }
  };

  return (
    <Autocomplete
      includeInputInList
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => value && option.id === value.id}
      getOptionLabel={(opt) => opt.name ?? ""}
      options={options ?? []}
      renderOption={(props, option) => (
        <Box {...props} key={option.id} component="li">
          {option.name}
        </Box>
      )}
      value={selectedProject || null}
      onInputChange={(_, value) => {
        setInputText(value || "");
      }}
      onChange={async (_, value) => {
        //TODO: cannot set value to the form
        if (value) {
          const project = projects?.find((p) => p.id === value.id);
          if (project) {
            if (project?.account?.active ?? true) {
              setValue("accountId", project.accountId);
              setValue("accountName", project.accountName);
            }
            const contact = await getContactById(project.contactId);
            if (!contact) {
              setValue("contactId", null);
              setValue("contactName", null);
            } else if (contact.active ?? true) {
              setValue("contactId", project.contactId);
              setValue("contactName", project.contactName);
            }
          }
          setValue("projectId", project?.id);
          setValue("type", project?.type);
          setSelectedProject({ name: project?.name, id: project?.id });
        } else {
          setValue("accountId", null);
          setValue("accountName", null);
          setValue("contactId", null);
          setValue("contactName", null);
          setValue("projectId", null);
          setValue("type", null);
          setSelectedProject(null);
        }
      }}
      loading={isLoading}
      renderInput={(params) => (
        <Controller
          render={({ field }) => (
            <TextField
              sx={{ width: "100%" }}
              label="過去案件"
              variant="outlined"
              {...field}
              {...params}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password",
                endAdornment: (
                  <>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          control={control}
          name="pastProjectId"
        />
      )}
    />
  );
}
